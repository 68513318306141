@import "../../../assets/scss/icons.scss";
@import "../../../assets/scss/bootstrap.scss";
@import "../../../assets/scss/app.scss";
@import "../../../assets/scss/new_varaibles";
.login-container {
    background: $report-success;
    height: 100vh;
    width: 50% !important;
}

.login-left {
    width: 50% !important;
}

.login {
    width: 100% !important;
}

.forget-pass {
    width: 70% !important;
}