@import "../../assets/scss/icons.scss";
@import "../../assets/scss/bootstrap.scss";
@import "../../assets/scss/app.scss";
@import "../../assets/scss/new_varaibles";
.swiper {
    width: 100%;
    height: 70%;
    margin-top: 4em;
    margin-bottom: 4em
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #ffffff;
    opacity: 1;
    background: #000000;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: $report-success;
}