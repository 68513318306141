@import "../../../assets/scss/icons.scss";
@import "../../../assets/scss/bootstrap.scss";
@import "../../../assets/scss/app.scss";
@import "../../../assets/scss/new_varaibles";
.logo {
    width: 50%;
    margin: 0 auto;
}

.logo img {
    width: 100%;
}

.swiper {
    width: 100%;
    height: 50%;
    // margin-top: 4em;
    // margin-bottom: 4em
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #ffffff;
    opacity: 1;
    background: #ffffff;
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: yellow;
}