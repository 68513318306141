$myFont: Helvetica,
sans-serif;
$myColor: red;
$myFontSize: 18px;
$myWidth: 680px;
$border-radius: 15px;
$background: #26A958;
$header-Border-raddius-top-left: 15px;
$header-Border-raddius-top-right: 15px;
$myWidth: 680px;
$border-radius: 15px;
$background: #26A958;
$secondary_bg: #63DA0A;
$report-danger: #E8345F;
$report-warning: #F9BB14;
$report-primary: #3765AE;
$report-success: #26A958;
$color:#26A958;
$commercial-clr: #63da0a