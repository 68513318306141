

.radio-toolbar input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  background-color: #ffffff;
  box-shadow:  0 0 .4em rgb(217, 217, 217);
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 100px;
}

.radio-toolbar input[type="radio"]:checked + label {
  background-color: #147860;
  border: none;
  color: white;
}

#radio-title {
  margin-bottom: 5px;
}
.rightbar-title{ 
  background: #147860; 
  color: white
}
.leftSidebar_white, .leftSidebar_green, .leftSidebar_green_2{
  width: 50px;
  height: 50px;
  border-radius: 100px;
  box-shadow:  0 0 .4em rgb(217, 217, 217);
  margin: 0 .4em;
}
.leftSidebar_white{
  background: white !important;
}
.leftSidebar_green{
  background: #147860 !important;
}
