// 
// Extras pages.scss
//


// pricing


.plan-box{
    .plan-btn{
        position: relative;

        &::before{
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background: $gray-300;
            left: 0px;
            right: 0px;
            top: 12px;
        }
    }
}

/* directory */
.social-links {
  li {
    a {
      margin-bottom: 6px;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}




// FAQ


.faq{
  position: relative;
  .accordion-header{
      border:none;
      background: transparent;
  }
} 


.accordion {
  .accordion-item {
    border-bottom: 1px solid $gray-200 !important;
    border-radius: 4px !important;
    box-shadow: none;

  }

  .accordion-button{
    font-weight: $fw-medium;
  }
}

.faq[data-bs-toggle=collapse]{
  &:after {
    content: '\F0377';
    display: block;
    font-family: 'Material Design Icons';
    font-size: 20px;
    color: $text-muted;
    position: absolute;
    right: 20px;
    font-weight: $fw-medium;
    top: 50%;
    background-image: none;
    transform: translateY(-50%);
  }
  &.collapsed::after {
    content: '\F0419';
  }
}
